const requisites = {
  TIN: 14452225584252221,
  OGRN: 1414562324222221225,
  name: 'Таким образом реализация намеченных плановы',
  OKVED: 122145464635232125,
  legalAddress: 'Таким образом реализация намеченных плановы',
  director: 'Таким образом реализация намеченных плановы',
  email: 'info@sitno.ru',
  byDeputy: 'Таким образом реализация намеченных плановы'
}

export const userOrganaizer = {
  contractorType: 'Резидент РФ',
  email: 'infotest@sitno.ru',
  name: 'Петр',
  organizationType: 'Юридическое лицо',
  requisites,
  role: 'Организатор ТП',
  secondName: 'Петрович',
  surname: 'Петров',
  tel: '8 900 800-80-80'
}

export const userBidder = {
  contractorType: 'Резидент РФ',
  email: 'infotest@sitno.ru',
  name: 'Петр',
  organizationType: 'Юридическое лицо',
  requisites,
  role: 'Участник ТП',
  secondName: 'Петрович',
  surname: 'Петров',
  tel: '8 900 800-80-80',
  status: 'Претендент'
}

export const userBidderAccredited = {
  contractorType: 'Резидент РФ',
  email: 'infotest@sitno.ru',
  name: 'Петр',
  organizationType: 'Юридическое лицо',
  requisites,
  role: 'Участник ТП',
  secondName: 'Петрович',
  surname: 'Петров',
  tel: '8 900 800-80-80',
  status: 'Аккредитован'
}
