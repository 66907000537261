<template>
  <VRequisitesList
    :requisites="userOrganaizer.requisites"
  />
</template>

<script>
import VRequisitesList from '@/components/profile/VRequisitesList'
import { userOrganaizer } from '@/mocks/users'

export default {
  setup () {
    return {
      userOrganaizer
    }
  },
  components: {
    VRequisitesList
  }
}
</script>

<style scoped>

</style>
